/* Styles go here */

.padding50{
    padding: 50px 20px;
}

.table-cell{
    display: table-cell;
}
.vetical-align-middle{
    vertical-align: middle;
}

.loaderScreen{
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0px;
    background: rgb(128 128 128 / 40%);
    position: absolute;
    z-index: 9;
}

.loaderScreen span{
    font-size: 28px;
    position: relative;
    top: 50%;
    z-index: 9999;
    font-weight: bold;
}

.css-nq5jxc {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 24px;
}

.css-jojjwf {
    margin: 0px;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(23, 33, 94);
}

.css-10si35k {
    margin: 0px;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
}

.css-ddmltu {
    padding-top: 32px;
    padding-bottom: 24px;
}

.css-79elbk {
    position: relative;
}

.css-fi9125 {
    font-size: 24px;
    font-weight: 500;
    padding: 12px 90px 12px 16px;
    text-align: right;
    border-radius: 0px;
    border: 1px solid rgb(85, 85, 85);
    background: white;
    width: 100%;
    outline: none;
}

.css-fi9126 {
    font-size: 24px;
    font-weight: 500;
    padding: 12px;
    text-align: right;
    border-radius: 0px;
    border: 1px solid rgb(85, 85, 85);
    background: white;
    width: 100%;
    outline: none;
}

.css-viowja {
    margin: 0px;
    font-family: Montserrat;
    line-height: 1.5;
    font-size: 24px;
    position: absolute;
    top: 9px;
    right: 18px;
    font-weight: 500;
    color: black;
}

.css-5vb4lz {
    margin-top: 24px;
    margin-bottom: 24px;
}

.css-39bbo6 {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.css-nq5jxc {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 24px;
}
.css-vlu4wp {
    margin: 0px;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 1.5;
    color: rgb(23, 33, 94);
    font-weight: bolder;
}

.css-j1ayh8 {
    margin: 0px;
    font-size: 24px;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
    font-weight: bolder;
}

.css-m7b68o {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.css-5y52ga {
    box-sizing: border-box;
    margin: 24px 8px 0px 0px;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.css-u1usi.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.css-xj09ev {
    box-sizing: border-box;
    margin: 24px 0px 0px 8px;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.css-u1usi.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.css-u1usi, .css-4sd5su {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat;
    line-height: 1.75;
    text-transform: uppercase;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(244, 181, 45);
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1.2rem;
    padding: 10px;
    min-width: 138px;
    box-shadow: rgb(0 0 0 / 59%) 6px 6px 20px 6px;
    color: rgb(23, 33, 94);
}

.MuiCardContent-root{
    width:600px;
    margin:auto;
}

.css-rvkvz4 {
    margin: 0px;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
    border-bottom: 6px solid;
    padding-bottom: 8px;
}

.css-1v3caum {
    padding-top: 16px;
}

.css-8rnkcc {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.css-l3b8w4 {
    margin: 0px 0px 0.35em;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(23, 33, 94);
}

.css-1s9uqdj {
    margin: 0px 0px 0.35em;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
    text-align: center;
}
.css-eyb7os {
    font-size: 10px;
    font-weight: 300;
    padding: 10px 12px;
    border-radius: 0px;
    border: 1px solid rgb(85, 85, 85);
    background: white;
    width: 100%;
    outline: none;
    color: rgb(23, 33, 94);
}
.css-dir1dn {
    margin: 16px 0px 0px;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.43;
    color: rgb(23, 33, 94);
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
}